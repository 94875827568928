<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> <div>订单补录</div> </template>
    </HeaderBox>
    <div class="selects">
      <div class="findItem">
        <!-- 用户类型 -->
        <a-select placeholder="用户类型" style="width: 120px" v-model="user_type">
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option value="1"> 个人 </a-select-option>
          <a-select-option value="2"> 机构 </a-select-option>
        </a-select>
        <a-input v-model="userName" placeholder="请输入手机号/机构码" />
        <!-- 支付方式 -->
        <a-select placeholder="支付方式" style="width: 120px" v-model="payMethod">
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option v-for="item in payType" :key="item.key">{{item.value}}</a-select-option>
        </a-select>
        <a-form-item label="支付时间：" style="display: inline-flex;margin:0; margin-left: 20px;">
          <a-range-picker
            valueFormat="YYYY-MM-DD HH:mm:ss"
            showTime
            v-model="findtime"
          ></a-range-picker>
        </a-form-item>
      </div>
      <div class="findItem">
        <a-button type="primary" @click="search()" v-hasPermi="['onlineorder:list:query']">搜索</a-button>
        <a-button class="all_btn_border btnBorder" @click="reset">重置</a-button>
        <a-button type="primary" @click="add()" v-hasPermi="['appendorder:info:add']">新建</a-button>
      </div>
    </div>
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.orderId"
        :loading="loadList"
        :columns="columns"
        :data-source="tableData"
        :scroll="{ x: true }"
        @change="onPage"
        :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, index">
          <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
          {{ (pageNumber - 1) * pageSize + index + 1 }}
        </template>
        <template slot="productName" slot-scope="text">
          <p class="productName" :title="text">{{ text ? text : "--" }}</p>
        </template>

        <template slot="payment" slot-scope="text">
          {{ text ? text : "--" }}
        </template>
          
        <template slot="name" slot-scope="text, item">
          {{ item.name}}
        </template>

        <template slot="userName" slot-scope="text, item">
          <!-- text ? $encryption('empty','phone',text) : "--" -->
          {{ item.userType == 1 ? (item.mobile ? $encryption('empty','phone',item.mobile) : "--") : item.userName }}
        </template>

        <template slot="payMethodTxt" slot-scope="text, item">
          {{ text ? text : item.payMethod == 6?'赠品':'-' }}
        </template>

        <template slot="changeProductFlag" slot-scope="text">
          {{ text ? (text == 2?'换课新订单':text == 1?'换课老订单':'非换课订单') : "--" }}
        </template>
        
        <template slot="tradeId" slot-scope="text">
          {{ text ? text : "--" }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <a
              @click="gonewdetail(record.orderId, record.userType, record.userId)"
              >补录记录</a
            >
            <span>|</span>
            <a
              @click="godetail(record.orderId, record.userType)"
              >订单详情</a
            >
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    fixed: "left",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "订单编号",
    align: "center",
    width: "160px",
    dataIndex: "code",
  },
  {
    title: "姓名/机构名称",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "手机号/机构码",
    align: "center",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "用户类型",
    align: "center",
    dataIndex: "userTypeTxt",
  },
  {
    title: "支付方式",
    align: "center",
    dataIndex: "payMethodTxt",
    scopedSlots: { customRender: "payMethodTxt" }
  },
  {
    title: "支付交易号",
    align: "center",
    dataIndex: "tradeId",
    scopedSlots: { customRender: "tradeId" },
  },
  {
    title: "实收款",
    align: "center",
    dataIndex: "payPrice",
    scopedSlots: { customRender: "payment" },
  },
  {
    title: "支付时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "换课订单类型",
    align: "center",
    dataIndex: "changeProductFlag",
    scopedSlots: { customRender: "changeProductFlag" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  data() {
    return {
      columns,
      user_type: undefined,
      userName: '',
      findtime: ['',''],
      payMethod: undefined,
      payType: [
        {
          key: 1,
          value: '微信'
        },{
          key: 2,
          value: '支付宝'
        },{
          key: 3,
          value: '对公转账'
        },{
          key: 6,
          value: '赠品'
        }
      ],
      loadList: false, // 表格loading
      tableData: [], // 表格内容
      total: 0,
      pageNumber: 1,
      pageSize: 10,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    // 跳转
    godetail(e, r) {
      if (r == 2) {
        this.$router.push("/admin/OrderConfig/orgOrderDetail?orderId=" + e);
      } else {
        this.$router.push("/admin/OrderConfig/OrderDetail?orderId=" + e + '&flag=1');
      }
    },
    gonewdetail(e,type,userId) {
      this.$router.push("/admin/OrderConfig/NewOrderSupple?orderId=" + e + "&type=" + type + "&userId=" + userId);
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    // 重置
    reset() {
      this.user_type = undefined;
      this.userName = '';
      this.findtime = ['',''];
      this.payMethod = undefined;
      this.pageNumber = 1;
      this.getList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.getList();
    },
    // 新建
    add() {
      this.$router.push('/admin/OrderConfig/NewOrderSupple')
    },
    getList() {
      this.loadList = true;
      this.$ajax({
        url:'/hxclass-management/order/replenishment/list',
        params:{
          page_num: this.pageNumber,
          page_size: this.pageSize,
          userName: this.userName,
          start_time: this.findtime[0] ? this.findtime[0] : "",
          end_time: this.findtime[1] ? this.findtime[1] : "",
          user_type: this.user_type,
          createType: 2, // 1:个人订单 2：补录订单
          payMethod: this.payMethod
        }
      }).then(res=>{
        this.loadList = false;
        if(res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }else{
          this.$message.error(res.message);
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .selects {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
  }
  .findItem {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    // /deep/.ant-form-item-control-wrapper{
    //   width: 240px !important;
    //   height: 29px !important;
    // }
    /deep/.ant-input {
      width: 300px !important;
      height: 29px !important;
      font-size: 12px !important;
      color: #999999 !important;
      line-height: 14px !important;
      margin-right: 20px;
      margin-bottom: 0 !important;
    }
    /deep/.ant-select,/deep/.ant-select-selection--single,/deep/.ant-select-selection__rendered{
      height: 29px !important;
      line-height: 29px !important;
      font-size: 12px !important;
    }
    .ant-btn {
      margin-right: 24px;
    }
    .ant-btn:last-child {
      margin-right: 0;
    }
  }
</style>